import { useSelector } from 'react-redux';
import { FormT } from '@profesia/mark-offer-form/components/content/form/types';
import { OfferLanguage } from '@profesia/mark-offer-form/codebooks/offerLanguage';
import { RootState } from '../../../stores/types';
import { mapReceivedDataToForm } from '../../Job/JobAdvertisement/mapReceivedDataToForm';
import Language from '../../../helpers/Language';

const useCompanyData = () => {
    const { companyId, companyCharacteristics, businessArea } = 
        useSelector((state: RootState) => state.loginManagerModel.company);

    return {
        company: {
            businessArea,
            characteristics: companyCharacteristics,
            id: companyId,
        },
    };
};

export const useCreateFormData = (): Partial<FormT> => {
    const contactPersonId = useSelector((state: RootState) => state.loginManagerModel.user?.id);
    const companyData = useCompanyData();
    const createFormData = {
        ...{ contactPersonId },
        ...companyData,
        language: Language.getDefaultLanguage() as OfferLanguage,
    };

    return createFormData;
};

export const useUnsavedCreateFormData = (): FormT => {
    const contactPersonId = useSelector((state: RootState) => state.loginManagerModel.user?.id);
    const companyData = useCompanyData();
    const positionData = useSelector((state: RootState) => state.createJobModel.jobData);
    const createFormData = {
        ...positionData,
        ...{ contactPersonId },
        ...companyData,
    };

    return createFormData;
};

export const useEditFormData = (): FormT => {
    const contactPersonId = useSelector((state: RootState) => state.jobModel.job?.user.id);
    const companyData = useCompanyData();
    const positionData = useSelector((state: RootState) => state.jobModel.job?.position);
    const editFormData = {
        ...mapReceivedDataToForm(positionData),
        ...{ contactPersonId },
        ...companyData,
    };

    return editFormData;
};
