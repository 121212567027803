import React, { FC } from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';

type RemoveAttachmentDialogProps = {
    title: string;
    onRemoveAttachment: () => void;
    onClose: () => void;
};

const RemoveAttachmentDialog: FC<RemoveAttachmentDialogProps> = ({
    title,
    onRemoveAttachment,
    onClose,
}) => {
    return (
        <MarkDialog
            message={{
                type: 'exclamation',
                title: `${Translations.getStatic('removeAttachmentDialogTitle')} ${title}`,
                infoLines: [Translations.getStatic('removeAttachmentDialogText')],
            }}
            actions={[
                {
                    title: Translations.getStatic('delete'),
                    action: () => {
                        onRemoveAttachment();
                        onClose();
                    },
                    type: 'negative',
                },
                {
                    title: Translations.getStatic('cancel'),
                    action: onClose,
                },
            ]}
        />
    );
};

export default RemoveAttachmentDialog;
