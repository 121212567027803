import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { styled } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getColor } from '@profesia/adamui/styles/colors';
import Calendar from '@profesia/adamui/components/common/icons/singleColor/Calendar';
import { InputField } from '@profesia/adamui/components/common/input';
import useDateFnsLocale from '@profesia/adamui/components/common/datepicker/DatePicker/useDateFnsLocale';
const DatePicker = ({ value, language = 'sk', ...props }) => {
    const [open, setOpen] = useState(false);
    const dateFormat = 'dd.M.yyyy';
    const adapterLocale = useDateFnsLocale(language);
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: adapterLocale, children: _jsx(StyledDatePicker, { ...props, value: value, format: dateFormat, open: open, onClose: () => setOpen(false), views: ['month', 'year', 'day'], dayOfWeekFormatter: (dayOfWeek) => dayOfWeek.substring(0, 2).toUpperCase(), showDaysOutsideCurrentMonth: true, slots: {
                openPickerButton: Calendar,
                textField: InputField,
            }, slotProps: {
                textField: {
                    onClick: () => setOpen(true),
                },
                day: {
                    disableTouchRipple: true,
                },
                switchViewButton: {
                    disableTouchRipple: true,
                },
                previousIconButton: {
                    disableTouchRipple: true,
                },
                nextIconButton: {
                    disableTouchRipple: true,
                },
            } }) }));
};
const StyledDatePicker = styled((DesktopDatePicker), {
    shouldForwardProp: (prop) => !['height', 'isError'].includes(prop),
})(({ theme: { palette, typography, spacing } }) => {
    return {
        '&.MuiTextField-root': {
            width: '160px',
            backgroundColor: getColor(palette.white),
        },
        '.MuiOutlinedInput-root .MuiOutlinedInput-input': {
            fontSize: typography.body2.fontSize,
            '::selection': { background: 'none' },
        },
        '& .MuiSvgIcon-Calendar': {
            color: getColor(palette.neutralLight4),
        },
        '.MuiInputBase-root': {
            paddingRight: spacing(1),
        },
    };
});
export default DatePicker;
