import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireEditorNewQuestionPicker from './QuestionnaireEditorNewQuestionPicker';
import QuestionnaireEditorQuestionsList from './QuestionnaireEditorQuestionsList';

const QuestionnaireEditor = props => {
    if (props.questionnaireEditorModel.hasOwnProperty('questionnaireData') === false) {
        return null;
    }

    const { questionnaireData } = props.questionnaireEditorModel;

    return (
        <div className={!props.modalType ? "light-group" : 'light-modal'}>
            <QuestionnaireEditorQuestionsList
                questions={questionnaireData.questions}
                onDeleteQuestionItem={props.onDeleteQuestionItem}
                onAddAnswerItem={props.onAddAnswerItem}
                onDeleteAnswerItem={props.onDeleteAnswerItem}
                onUpdateAnswerItem={props.onUpdateAnswerItem}
                onUpdateQuestionItem={props.onUpdateQuestionItem}
                onReorderQuestionItem={props.onReorderQuestionItem}
            />
            <QuestionnaireEditorNewQuestionPicker
                usedQuestionTypes={questionnaireData.questions.map(question => question.type)}
                onAddQuestionItem={props.onAddQuestionItem}
                defaultOpen={props.defaultOpenNewQuestionPicker}
            />
        </div>
    );
};

QuestionnaireEditor.propTypes = {
    questionnaireEditorModel: PropTypes.shape({
        questionnaireData: PropTypes.object,
        settings: PropTypes.object,
    }),
    defaultOpenNewQuestionPicker: PropTypes.bool,
    onAddQuestionItem: PropTypes.func.isRequired,
    onDeleteQuestionItem: PropTypes.func.isRequired,
    onUpdateQuestionItem: PropTypes.func.isRequired,
    onAddAnswerItem: PropTypes.func.isRequired,
    onDeleteAnswerItem: PropTypes.func.isRequired,
    onUpdateAnswerItem: PropTypes.func.isRequired,
    onReorderQuestionItem: PropTypes.func.isRequired,
};

QuestionnaireEditor.defaultProps = {
    defaultOpenNewQuestionPicker: false,
};

export default QuestionnaireEditor;
