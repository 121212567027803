import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/material';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { Typography } from '@profesia/adamui/components/common/typography';
import ImageCropper from '@profesia/adamui/components/common/ImageCropper/index';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import StyledModal from '@profesia/mark-offer-form/components/content/form/components/StyledModal';
import { useLogoContext } from '@profesia/mark-offer-form/services/LogoContext';
const EditLogoModal = ({ isOpen, onClose, isModalSecond }) => {
    const t = useTranslationInPageLanguage('companyDetails.editLogoModal');
    const formData = useForm();
    const { base64Logo, setBase64Logo, setIsEdited } = useLogoContext();
    const [editedLogo, setEditedLogo] = useState(null);
    const onUse = () => {
        setBase64Logo(editedLogo);
        setIsEdited(true);
        onClose();
    };
    const onDelete = () => {
        setBase64Logo(null);
        setEditedLogo(null);
        setIsEdited(false);
        onClose();
    };
    return (_jsx(StyledModal, { isOpen: isOpen, onClose: onClose, spacing: 4, isModalSecond: isModalSecond, header: _jsx(Typography, { variant: "h3Medium", ml: 3, children: t('title') }), content: _jsxs(FormProvider, { ...formData, children: [_jsx(ImageCropper, { imageSrc: base64Logo || '', mainLabel: t('companyLogo'), previewLabel: t('preview'), aspect: 2.4, onCropChange: (base64) => setEditedLogo(base64), imgFormat: "jpeg", maxCropperBoxHeight: 264, maxPreviewBoxHeight: 100 }), _jsx(StyledFormFooter, { leftSideActions: [
                        {
                            label: t('deleteLogo'),
                            variant: 'destructive',
                            onClick: onDelete,
                        },
                    ], rightSideActions: [
                        {
                            onClick: onUse,
                            variant: 'primary',
                            label: t('useButton'),
                        },
                        {
                            onClick: onClose,
                            variant: 'secondary',
                            label: t('cancelButton'),
                        },
                    ] })] }) }));
};
const StyledFormFooter = styled(FormFooter)(() => ({
    paddingLeft: 0,
}));
export default EditLogoModal;
