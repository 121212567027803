import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Constants from '../../helpers/Constants';
import Translations from '../../helpers/Translations';
import User from '../../helpers/User';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import RemoveAttachmentDialog from '../Dialogs/RemoveAttachmentDialog';

const AttachmentOptions = ({
    isDuplicate,
    language,
    displaySize,
    attachment,
    openAttachment,
    onRemoveCandidateAttachment,
    isAdmin,
    loginManagerModel,
    jobModel,
}) => {
    const [isRemoveAttachmentDialogOpen, setRemoveAttachmentDialogOpen] = useState(false);

    const shouldShowDeleteOption =
        !isDuplicate &&
        attachment.attachmentTypeId !== Constants.ATTACHMENT_TYPE_QUESTIONNAIRE_RESULT &&
        !User.isExternalVisitor(loginManagerModel, jobModel);

    if (displaySize.width < Constants.WIDTH_NO_MODAL) {
        return (
            <>
                <Dropdown
                    className="icon"
                    direction="left"
                    icon={{ className: 'icon-more_small mini' }}
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            text={Translations.getStatic('show', language)}
                            value={Translations.getStatic('show', language)}
                            onClick={openAttachment}
                        />

                        {shouldShowDeleteOption && (
                            <Dropdown.Item
                                text={Translations.getStatic('delete', language)}
                                value={Translations.getStatic('delete', language)}
                                onClick={() => setRemoveAttachmentDialogOpen(true)}
                            />
                        )}
                    </Dropdown.Menu>
                </Dropdown>

                {isRemoveAttachmentDialogOpen && (
                    <RemoveAttachmentDialog
                        title={
                            AttachmentAssistantHelper.getAttachmentTypeLabels(language)[
                                attachment.attachmentTypeId
                            ]
                        }
                        onClose={() => {
                            setRemoveAttachmentDialogOpen(false);
                        }}
                        onRemoveAttachment={onRemoveCandidateAttachment}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <a onClick={openAttachment} className="link secondary-link">
                {Translations.getStatic('show', language)}
            </a>

            <a
                target="_blank"
                href={attachment.downloadUrl}
                className={
                    'link secondary-link' + (attachment.exists === false && isAdmin ? ' red' : '')
                }
            >
                {Translations.getStatic('download', language)}
            </a>

            {shouldShowDeleteOption && (
                <>
                    <Dropdown
                        className="icon"
                        direction="left"
                        icon={{ className: 'icon-more_small mini' }}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={Translations.getStatic('delete', language)}
                                value={Translations.getStatic('delete', language)}
                                onClick={() => setRemoveAttachmentDialogOpen(true)}
                            />
                        </Dropdown.Menu>
                    </Dropdown>

                    {isRemoveAttachmentDialogOpen && (
                        <RemoveAttachmentDialog
                            title={
                                AttachmentAssistantHelper.getAttachmentTypeLabels(language)[
                                    attachment.attachmentTypeId
                                ]
                            }
                            onClose={() => setRemoveAttachmentDialogOpen(false)}
                            onRemoveAttachment={onRemoveCandidateAttachment}
                        />
                    )}
                </>
            )}
        </>
    );
};

AttachmentOptions.propTypes = {
    isDuplicate: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    displaySize: PropTypes.object.isRequired,
    attachment: PropTypes.object.isRequired,
    openAttachment: PropTypes.func.isRequired,
    onRemoveCandidateAttachment: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    loginManagerModel: PropTypes.object.isRequired,
    jobModel: PropTypes.object.isRequired,
};

export default AttachmentOptions;
