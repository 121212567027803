import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { SectionWrapperColorVariant } from '@profesia/adamui/components/common/types';
import { useMobile } from '@profesia/adamui/utils/responsive';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT, useTranslationInPageLanguage, useGetLocale, } from '@profesia/mark-offer-form/i18n';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import LogoProvider from '@profesia/mark-offer-form/services/LogoContext';
import { FORM_COMPANY_BUSINESS_AREA, FORM_COMPANY_CHARACTERISTICS, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import CompanyModal from './Modal';
import CompanyDetailsContent from './Content';
const CompanyDetails = () => {
    const currentLanguage = useGetLocale();
    const t = useGetFixedT('companyDetails');
    const pageT = useTranslationInPageLanguage('companyDetails');
    const { form } = getConfiguration();
    const isMobile = useMobile('sm');
    const editLink = pageT(isMobile ? 'links.editLinkShort' : 'links.editLinkFull');
    const { isEditMode } = useOptionsContext();
    const { getValues, setValue, reset } = useFormContext();
    const characteristics = getValues(FORM_COMPANY_CHARACTERISTICS);
    const businessArea = getValues(FORM_COMPANY_BUSINESS_AREA);
    const [company, setCompany] = useState({
        characteristics: characteristics[currentLanguage],
        businessArea: { id: businessArea.id, name: businessArea.names[currentLanguage] },
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
        if (isEditMode) {
            form.openModalSecond();
        }
    };
    const handleSubmitData = (data) => {
        setCompany({
            characteristics: data.characteristics,
            businessArea: {
                id: data.businessArea.id,
                name: data.businessArea.names[data.languageFlag],
            },
        });
        setValue(FORM_COMPANY_CHARACTERISTICS, {
            ...characteristics,
            [data.languageFlag]: data.characteristics,
        });
        setValue(FORM_COMPANY_BUSINESS_AREA, data.businessArea);
        reset({ FORM_COMPANY_CHARACTERISTICS, FORM_COMPANY_BUSINESS_AREA }, { keepValues: true });
        handleCloseModal();
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (isEditMode) {
            form.closeModalSecond();
        }
    };
    useEffect(() => {
        setCompany({
            characteristics: characteristics[currentLanguage],
            businessArea: {
                id: businessArea.id,
                name: businessArea.names[currentLanguage],
            },
        });
    }, [currentLanguage]);
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), endComponent: _jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: handleOpenModal, children: editLink }), variant: SectionWrapperColorVariant.Dark, children: _jsx(CompanyDetailsContent, { characteristics: company.characteristics, businessAreaName: company.businessArea.name }) }), isModalOpen ? (_jsx(LogoProvider, { base64Logo: null, isEdited: false, children: _jsx(CompanyModal, { company: { company }, onSubmit: handleSubmitData, onClose: handleCloseModal, isOpen: isModalOpen }) })) : null] }));
};
export default CompanyDetails;
