import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, styled } from '@mui/material';
import getHighlightedText from '@profesia/adamui/utils/getHighlightedText';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
const OptionLabel = ({ label, isHighlightingEnabled, inputValue }) => {
    const highlightedText = isHighlightingEnabled === undefined || isHighlightingEnabled
        ? getHighlightedText(inputValue, label)
        : [];
    if (!highlightedText.length) {
        return _jsx(_Fragment, { children: label });
    }
    const [left, middle, right] = highlightedText;
    return (_jsxs(_Fragment, { children: [left, _jsx(Highlighter, { children: middle }), right] }));
};
const Highlighter = styled(TypographySpan)(({ theme: { palette } }) => ({
    backgroundColor: alpha(getColor(palette.primaryOrange), 0.2),
}));
export default OptionLabel;
