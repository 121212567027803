import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Button from '../../Button';
import Grid from '../../Grid';
import Divider from '../../Divider';
import Heading from '../../Heading';
import Icon from '../../Icon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RadioToggle from '../../RadioToggle';
import Arrays from '../../../helpers/Arrays';
import Translations from '../../../helpers/Translations';
import UserRights from '../../../helpers/UserRights';
import DateFormatted from '../../../helpers/DateFormatted';

class PipelineTemplateEditor extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            confirmed: false,
        };
    }

    confirmChanges = () => {
        this.setState({
            confirmed: true,
        });
    };

    addCustomStage = () => {
        const stages = this.props.pipelineTemplateEditorModel.pipelineStagesForEditor;

        stages.push({
            stageId: null,
            stageName: '',
            isDefault: false,
            isFixedOnPipeline: false,
            tempStageId: DateFormatted.getTimestamp(),
        });

        this.props.setPipelineStages(stages);
    };

    updateCustomStage = (index, value) => {
        const stages = this.props.pipelineTemplateEditorModel.pipelineStagesForEditor.map((stage, i) => ({
            ...stage,
            stageName: i === index
                ? value
                : stage.stageName,
        }));

        this.props.setPipelineStages(stages);
    };

    removeCustomStage = (index) => {
        let stages = this.props.pipelineTemplateEditorModel.pipelineStagesForEditor;

        stages.splice(index, 1);

        this.props.setPipelineStages(stages);
    };

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const stages = Arrays.reorderArray(
            this.props.pipelineTemplateEditorModel.pipelineStagesForEditor,
            result.source.index,
            result.destination.index,
        );

        this.props.setPipelineStages(stages);
    };

    reorderStages = (fromIndex, toIndex) => {
        const stages = Arrays.reorderArray(
            this.props.pipelineTemplateEditorModel.pipelineStagesForEditor,
            fromIndex,
            toIndex,
        );

        this.props.setPipelineStages(stages);
    };

    handleSwitchableStage = (stage, isEnabled) => {
        if (isEnabled === true) {
            this.props.enableSwitchableFixedStage(stage);
        } else {
            this.props.disableSwitchableFixedStage(stage);
        }
    };

    render = () => {
        const {
                  pipelineTemplateEditorModel: {
                      settings: {
                          pipeline: {
                              maxCustomStagesCount,
                              fixedStagesFromStart,
                          },
                      },
                      pipelineStagesForEditor,
                      enabledSwitchableFixedStages,
                  },

                  loginManagerModel: {
                      activePackage,
                  },
                    modalType,
              } = this.props;

        const customStagesCount = pipelineStagesForEditor.filter(stage => stage.isFixedOnPipeline === false).length;
        const errors            = this.props.hasOwnProperty('errors') === true
            ? this.props.errors
            : [];

        const confirmSection = (
            <>
                <Heading as="h3">{Translations.getStatic('globalSetting')}</Heading>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <p>{Translations.getStatic('globalSettingDescription')}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Divider hidden className="small"/>
                            <Button className="primary-button" floated="left" onClick={this.confirmChanges}>{Translations.getStatic('changeGlobalSetting')}</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );

        const FixedStage = ({stage, enabledSwitchableStage, handleSwitchableStage}) => (
            <div className="segment  stage fixed">
                <Grid>
                    {
                        stage.isSwitchableOnPipeline === true
                            ? (
                                <>
                                    <Grid.Row>
                                        <Grid.Column width={10} verticalAlign="middle">
                                            <h4 className={'txt-regular' + (enabledSwitchableStage === true ? '' : ' txt-disabled')}>{Translations.getStage(stage)}</h4>
                                        </Grid.Column>
                                        <Grid.Column width={6} textAlign={this.props.displaySize.isMobile === true ? 'left' : 'right'} verticalAlign="middle">
                                            <RadioToggle
                                                label={enabledSwitchableStage === true ? Translations.getStatic('enabledStage') : Translations.getStatic('disabledStage')}
                                                className={this.props.displaySize.isMobile === true ? '' : 'float-right'}
                                                checked={enabledSwitchableStage === true}
                                                onChange={() => handleSwitchableStage(stage, !enabledSwitchableStage)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Divider hidden className="size_12"/>
                                            <p className={enabledSwitchableStage === true ? '' : 'txt-disabled'} dangerouslySetInnerHTML={{__html: Translations.getStatic('sourceExplanation')}}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </>
                            )
                            : (
                                <Grid.Row>
                                    <Grid.Column width={16} verticalAlign="middle">
                                        <h4 className={'txt-regular'}>{Translations.getStage(stage)}</h4>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                    }
                </Grid>
            </div>
        );
        const pipelineEditor = (
            <>
                <Heading
                    as="h3"
                    className={!modalType ? 'padding-left_none template-assistant' : ''}
                >
                    {Translations.getStatic('preciseSelectionTemplate')}
                </Heading>

                <div className={!modalType ? "light-group" : 'light-modal'}>
                    <Grid className={'collection-stages'}>
                        <Grid.Column className="padding-top_none padding-left_none padding-right_none">
                            <h5 className="text-form__label">{Translations.getStatic('unsortedCandidates')}</h5>
                            {
                                fixedStagesFromStart.map(fixedStage => (
                                    <FixedStage
                                        key={fixedStage.stageId}
                                        stage={fixedStage}
                                        enabledSwitchableStage={
                                            enabledSwitchableFixedStages.find(stage => stage.stageId === fixedStage.stageId) !== undefined
                                        }
                                        handleSwitchableStage={this.handleSwitchableStage}
                                    />
                                ))
                            }
                        </Grid.Column>
                    </Grid>

                    <Grid>
                        <Grid.Column className="padding-top_none padding-left_none padding-right_none">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <h5 className="text-form__label">{Translations.getStatic('preciseSelection')}</h5>
                                        <Divider hidden className="size_12"/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {
                                        provided => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    pipelineStagesForEditor.map((stage, index) => {
                                                        let reorderButtons = [];

                                                        if (stage.isFixedOnPipeline === false) {
                                                            reorderButtons.push(
                                                                index > 0
                                                                    ? <Button className="small" key={'up'} icon={{className: 'icon-up_dark small'}} onClick={() => this.reorderStages(index, index - 1)}/>
                                                                    : <Button className="small" key={'up'} icon={{className: 'icon-up_dark small'}} disabled/>,
                                                            );

                                                            reorderButtons.push(
                                                                index < pipelineStagesForEditor.length - 1
                                                                    ? <Button className="small" key={'down'} icon={{className: 'icon-down_dark small'}} onClick={() => this.reorderStages(index, index + 1)}/>
                                                                    : <Button className="small" key={'down'} icon={{className: 'icon-down_dark small'}} disabled/>,
                                                            );
                                                        }

                                                        return (
                                                            <Draggable isDragDisabled={stage.isFixedOnPipeline === true} key={index} draggableId={'' + (index + 1)} index={index}>
                                                                {provided => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Grid>
                                                                            <Grid.Row columns={2}>
                                                                                <Grid.Column className="main-column" verticalAlign="middle">
                                                                                    <div className={'segment  stage' + (stage.isFixedOnPipeline === false ? ' new-stage' : '')}>
                                                                                        <div className="vertical-align">
                                                                                            {
                                                                                                stage.isFixedOnPipeline === false
                                                                                                    ? (
                                                                                                        <Input
                                                                                                            maxLength={128}
                                                                                                            fluid
                                                                                                            autoFocus={stage.stageName.length === 0}
                                                                                                            labelPosition="right"
                                                                                                            placeholder={Translations.getStatic('fillStageName')}
                                                                                                            error={errors.includes('stage#' + index)}
                                                                                                            label={(
                                                                                                                <Icon
                                                                                                                    className="icon-cancel"
                                                                                                                    onClick={() => this.removeCustomStage(index)}
                                                                                                                />
                                                                                                            )}
                                                                                                            value={stage.stageName}
                                                                                                            onChange={(e) => this.updateCustomStage(index, e.target.value)}
                                                                                                        />
                                                                                                    )
                                                                                                    : <h4 className="txt-regular">{Translations.getStage(stage)}</h4>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Grid.Column>
                                                                                <Grid.Column className="padding-left_none button-column" verticalAlign="middle">
                                                                                    {
                                                                                        reorderButtons
                                                                                    }
                                                                                </Grid.Column>
                                                                            </Grid.Row>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })
                                                }

                                                {provided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>
                            </DragDropContext>
                        </Grid.Column>
                    </Grid>
                    {
                        customStagesCount < maxCustomStagesCount
                        && (
                            <Grid className="padding-top_none">
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <div
                                            role="link"
                                            className={'primary-link'}
                                            onClick={this.addCustomStage}
                                        >
                                            {Translations.getStatic('addStage')}
                                        </div>
                                        <Divider className="small" hidden/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Divider hidden className={!modalType ? 'size_16 multiple' : 'small'} />
                            </Grid>
                        )
                    }
                </div>
            </>
        );

        return (
            <>
                {
                    this.state.confirmed === true
                    || UserRights.hasAllowedMultiPipelines(activePackage) === true
                    || (this.props.editFromJob === true && UserRights.hasAllowedMultiPipelines(activePackage) === false)
                        ? pipelineEditor
                        : confirmSection
                }
            </>
        );
    };
}

PipelineTemplateEditor.propTypes = {
    loginManagerModel: PropTypes.object.isRequired,
    pipelineTemplateEditorModel: PropTypes.object.isRequired,
    setPipelineStages: PropTypes.func.isRequired,
    enableSwitchableFixedStage: PropTypes.func.isRequired,
    disableSwitchableFixedStage: PropTypes.func.isRequired,
    editFromJob: PropTypes.bool,
    modalType: PropTypes.string,
};

PipelineTemplateEditor.defaultProps = {
    editFromJob: false,
};

const mapStateToProps = (state) => ({
    modalType: state.modalLogic.modalType,
});

export default connect(mapStateToProps)(PipelineTemplateEditor);
