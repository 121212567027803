import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { SmartLink } from '@profesia/adamui/components/common/Link';
import { Button } from '@profesia/adamui/components/common/button';
import { LEFT_SIDE_ACTIONS_ID, ON_WRAP_CLASS, RIGHT_SIDE_ACTIONS_ID } from './consts';
import useWrapObserver from './useWrapObserver';
const FormFooter = ({ variant, leftSideActions, rightSideActions, className, }) => {
    useWrapObserver('form-footer');
    return (_jsxs(FormFooterContainer, { id: "form-footer", variant: variant, className: className, children: [_jsx(Box, { id: LEFT_SIDE_ACTIONS_ID, children: leftSideActions.map(({ label, variant, ...navigationProps }, key) => (_jsx(SmartLink, { variantType: variant, ...navigationProps, children: label }, key))) }), _jsx(Box, { id: RIGHT_SIDE_ACTIONS_ID, children: rightSideActions.map(({ label, variant, ...navigationProps }, key) => (_jsx(Button, { variant: variant, ...navigationProps, children: label }, key))) })] }));
};
const FormFooterContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme: { palette, spacing }, variant = 'white' }) => ({
    backgroundColor: getColor(palette[variant]),
    padding: spacing(3, 4),
    marginTop: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: spacing(1),
    '> .MuiBox-root': {
        display: 'flex',
        '&#left-side-actions': {
            gap: spacing(2),
            alignItems: 'center',
            [`&.${ON_WRAP_CLASS}`]: {
                marginLeft: 'auto',
                marginBottom: spacing(2),
            },
        },
        '&#right-side-actions': {
            marginLeft: 'auto',
            gap: spacing(1),
        },
    },
}));
export default FormFooter;
