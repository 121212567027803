import React from 'react';
import PropTypes from 'prop-types';
import {InputField} from '@profesia/adamui/components/common/input';
import Form from '../../Form';
import Button from '../../Button';
import Grid from '../../Grid';
import Divider from '../../Divider';
import Icon from '../../Icon';
import Translations from '../../../helpers/Translations';

const QuestionnaireEditorDisposableDateQuestion = props => {
    const moveUp = props.first === true
        ? null
        : (
            <Button icon={{className: 'button icon-up_dark small'}} onClick={() => props.onReorderQuestionItem(props.index, props.index - 1)}/>
        );

    const moveDown = props.last === true
        ? null
        : (
            <Button icon={{className: 'button icon-down_dark small'}} onClick={() => props.onReorderQuestionItem(props.index, props.index + 1)}/>
        );

    return (
        <Grid key={props.index}>
            <Grid.Column className="questionnaire-box main-column">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={1} verticalAlign="middle"><span className="text-form__label">{props.index + 1}.</span></Grid.Column>
                        <Grid.Column width={14} verticalAlign="middle">
                            <InputField maxLength={5000} value={props.question.question} onChange={e => e.preventDefault()} readOnly={true}/>
                        </Grid.Column>
                        <Grid.Column width={1} verticalAlign="middle" textAlign="right">
                            <Button icon={{className: 'button icon-cancel'}} onClick={() => props.onDeleteQuestionItem(props.index)}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider hidden className="size_8"/>
                    <Grid.Row>
                        <Grid.Column width={1}/>
                        <Grid.Column width={14}>
                            <Form.Field>
                                <InputField
                                    disabled
                                    icon={<Icon className="icon-calendar_small"/>}
                                    placeholder={Translations.getStatic('date')}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={1}/>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
            <Grid.Column className="button-column">
                {moveUp}
                {moveDown}
            </Grid.Column>
        </Grid>
    );
};

QuestionnaireEditorDisposableDateQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    onDeleteQuestionItem: PropTypes.func.isRequired,
    onUpdateQuestionItem: PropTypes.func.isRequired,
    onReorderQuestionItem: PropTypes.func.isRequired,
};

export default QuestionnaireEditorDisposableDateQuestion;
