import addError from '../addError';
const getCompanyErrors = ({ company }, t) => {
    let errors = {};
    const companyErrors = {
        ...addError('businessArea.id', !company.businessArea?.id, t('company.businessAreaId.required')),
        ...addError('characteristics', !company.characteristics.trim(), t('company.characteristics.required')),
    };
    const hasCompanyErrors = Object.keys(companyErrors).length > 0;
    errors = {
        ...(hasCompanyErrors && { company: companyErrors }),
    };
    return errors;
};
export default getCompanyErrors;
