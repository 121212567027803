import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getInputIcon } from '@profesia/adamui/components/common/icons/helpers';
import { SHOW_ON_FOCUS } from '@profesia/adamui/components/common/icons/classes';
const primaryProps = {
    width: 22,
    viewBox: '0 0 22 22',
    fill: 'none',
};
const mappedIconTypes = {
    checked: {
        primary: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { x: "2", y: "2", width: "18", height: "18", rx: "4", fill: "#2774F0" }), _jsx("rect", { className: SHOW_ON_FOCUS, x: "1", y: "1", width: "20", height: "20", rx: "5", stroke: "#2774F0", strokeOpacity: "0.25", strokeWidth: "2" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.8154 8L10.0588 12.6758L8.17986 10.8332L7 11.992L10.0596 15L16 9.15802L14.8154 8Z", fill: "white" })] })),
            props: primaryProps,
        },
        disabled: {
            path: (_jsxs("g", { opacity: "0.5", children: [_jsx("rect", { width: "18", height: "18", rx: "4", fill: "#7F7F7F" }), _jsx("path", { d: "M12.8154 6L8.05878 10.6758L6.17986 8.83319L5 9.99196L8.05957 13L14 7.15802L12.8154 6Z", fill: "#FEFEFE" })] })),
        },
        collapsible: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { width: "18", height: "18", rx: "4", fill: "#2774F0" }), _jsx("rect", { x: "5", y: "8", width: "8", height: "2", fill: "white" })] })),
        },
    },
    unchecked: {
        primary: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { width: "18", height: "18", x: "2", y: "2", fill: "#2774F0", rx: "4" }), _jsx("rect", { className: SHOW_ON_FOCUS, width: "20", height: "20", x: "1", y: "1", stroke: "#2774F0", strokeOpacity: ".25", strokeWidth: "2", rx: "5" }), _jsx("rect", { width: "17", height: "17", x: "2.5", y: "2.5", fill: "#fff", stroke: "#506B95", rx: "3.5" })] })),
            props: primaryProps,
        },
        disabled: {
            path: (_jsx("rect", { x: "0.5", y: "0.5", width: "17", height: "17", rx: "3.5", fill: "white", stroke: "#AAAAAA" })),
        },
        collapsible: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { width: "18", height: "18", rx: "4", fill: "#2774F0" }), _jsx("rect", { x: "5", y: "8", width: "8", height: "2", fill: "white" }), _jsx("rect", { x: "8", y: "13", width: "8", height: "2", transform: "rotate(-90 8 13)", fill: "white" })] })),
        },
    },
};
const CheckboxSquareIcon = ({ state = 'unchecked', type = 'primary', ...props }) => {
    const { path, props: defaultProps = {} } = mappedIconTypes[state][type];
    return getInputIcon(path, 'CheckboxSquare', state, {
        ...defaultProps,
        ...props,
    });
};
export default CheckboxSquareIcon;
