import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { useCodebooksContext } from '@profesia/mark-offer-form/services/CodebooksContext';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import Skeleton from './Skeleton';
import Content from './Content';
const Form = forwardRef(({}, ref) => {
    const { isLoading: isLoadingCodebooks } = useCodebooksContext();
    const { isEditMode, isSingleStep } = useOptionsContext();
    return (_jsx(_Fragment, { children: _jsx(ItemsStack, { isEditMode: isEditMode, isSingleStep: isSingleStep, children: isLoadingCodebooks ? _jsx(Skeleton, {}) : _jsx(Content, { ref: ref }) }) }));
});
const ItemsStack = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'isEditMode' && prop !== 'isSingleStep',
})(({ theme: { spacing, palette, breakpoints }, isEditMode, isSingleStep }) => ({
    ...(!isEditMode
        ? {
            backgroundColor: getColor(palette.neutralLight0),
        }
        : {}),
    padding: !isEditMode && isSingleStep ? spacing(0, 36.25, 0) : spacing(4),
    alignItems: 'center',
    [tabletDown]: {
        padding: 0,
        backgroundColor: 'white',
    },
}));
export default Form;
