import React from 'react';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import {InputField} from '@profesia/adamui/components/common/input';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import MarkForm from '../Form';
import Grid from '../Grid';
import Item from '../Item';
import Divider from '../Divider';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import Validation from '../../helpers/Validation';
import Form from '../../helpers/Form';

class TemplateAssistantTemplateHeader extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            showEditableCheckbox: !this.props.templateData.isPrivate,
            originalData: this.props.templateData,
        };
    }

    setTemplateSharing = shared => {
        this.setState({
            showEditableCheckbox: shared,
        });

        this.props.onUpdateTemplate({
            isPrivate: !shared,
            isEditable: false,
        });
    };

    doNotSaveTemplate = () => {
        this.props.onUpdateTemplate({
            isPrivate: this.state.originalData.isPrivate,
            isEditable: this.state.originalData.isEditable,
            isTemplate: this.state.originalData.isTemplate,
            isDefault: this.state.originalData.isDefault,
            name: this.state.originalData.name,
        });

        this.props.onHideNewTemplateHeader();
    };

    setTemplateDefault = isDefault => {
        this.props.onUpdateTemplate({
            isDefault,
        });
    };

    render() {
        const {templateData, templateType, customMessageForTemplateChangesWillSaveAfterAction, isParentTemplateAssistantSettings} = this.props;
        const {onHideNewTemplateHeader, onUpdateTemplate, errors, settings}                                                       = this.props;

        return (
            <>
                <Grid>
                    {
                        onHideNewTemplateHeader === null
                            ? null
                            : (
                                <Grid.Row className="grid-row__header">
                                    <Grid.Column width={8}>
                                        <Heading as="h5" className="template">{Translations.getStatic('saveAsNewTemplate')}</Heading>
                                    </Grid.Column>
                                    <Grid.Column width={8} textAlign="right">
                                        <Item as="a" className="primary-link" onClick={this.doNotSaveTemplate}>{Translations.getStatic('doNotSaveTemplate')}</Item>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                    }
                </Grid>

                <Grid className={isParentTemplateAssistantSettings === true ? 'highlighted border' : ''}>
                    <Grid.Row>
                        <Grid.Column width={templateType === Constants.TEMPLATE_TYPE_EMAIL ? 8 : 16}>
                            <MarkForm.Field
                                error={
                                    errors.includes('templateTitle') &&
                                    Form.check({
                                        fieldType: 'text',
                                        fieldValue: templateData.name,
                                    }) === false
                                        ? Validation.getMessage('empty', 'newTemplateLabel')
                                        : false
                                }
                            >
                                <InputField
                                    maxLength={128}
                                    value={templateData.name}
                                    label={<label>{Translations.getStatic('templateName')}</label>}
                                    onChange={(e) =>
                                        onUpdateTemplate({
                                            name: e.target.value,
                                            isTemplate: true,
                                        })
                                    }
                                />
                            </MarkForm.Field>
                        </Grid.Column>

                        {
                            templateType === Constants.TEMPLATE_TYPE_EMAIL && settings !== undefined && settings.hasOwnProperty('emailTemplateTypes') === true
                            && (
                                <Grid.Column width={8}>
                                    <div className="field">
                                        <label>{Translations.getStatic('templateType')}</label>
                                        <Select
                                            options={settings.emailTemplateTypes.map(emailTemplateType => ({
                                                key: emailTemplateType.id,
                                                id: emailTemplateType.id,
                                                label: Translations.getStatic(emailTemplateType.typeName),
                                            }))}
                                            value={templateData.type.id}
                                            onChange={(value) => onUpdateTemplate({
                                                type: settings.emailTemplateTypes.find(type => parseInt(value) === type.id),
                                                isTemplate: true,
                                            })}
                                        />
                                    </div>
                                </Grid.Column>
                            )
                        }
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field className="padding-bottom_none">
                                <Checkbox
                                    className="checkbox-align"
                                    onChange={(e) => this.setTemplateSharing(e.target.checked)}
                                    label={Translations.getStatic('shareTemplateLabel')}
                                    checked={!templateData.isPrivate}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>

                    {
                        this.state.showEditableCheckbox === true
                        && (
                            <>
                                <Grid.Row>
                                    <Grid.Column>
                                        <MarkForm.Field className="padding-bottom_none">
                                            <Checkbox
                                                className="checkbox-align"
                                                onChange={(e) => onUpdateTemplate({
                                                    isEditable: e.target.checked,
                                                })}
                                                label={Translations.getStatic('editableTemplateLabel')}
                                                checked={templateData.isEditable}
                                            />
                                        </MarkForm.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        )
                    }

                    {
                        (templateType === Constants.TEMPLATE_TYPE_AUTO_ANSWER || templateType === Constants.TEMPLATE_TYPE_PIPELINE)
                        && (
                            <Grid.Row>
                                <Grid.Column>
                                    <MarkForm.Field className="padding-bottom_none">
                                        <Checkbox
                                            className="checkbox-align"
                                            onChange={(e) => this.setTemplateDefault(e.target.checked)}
                                            label={Translations.getStatic('setTemplateAsDefault')}
                                            checked={templateData.isDefault}
                                        />
                                    </MarkForm.Field>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }

                    {
                        isParentTemplateAssistantSettings === true
                        && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Divider/>
                                    <p>
                                        <i>
                                            {
                                                customMessageForTemplateChangesWillSaveAfterAction === undefined
                                                    ? Translations.getStatic('templateChangesWillSaveAfterAction')
                                                    : customMessageForTemplateChangesWillSaveAfterAction
                                            }
                                        </i>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }
                </Grid>
                <Divider className="hidden medium"/>
            </>
        );
    }
}

TemplateAssistantTemplateHeader.propTypes = {
    templateData: PropTypes.shape({
        isPrivate: PropTypes.bool.isRequired,
        isTemplate: PropTypes.bool.isRequired,
        isEditable: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,

    templateType: PropTypes.string.isRequired,

    onUpdateTemplate: PropTypes.func.isRequired,
    onHideNewTemplateHeader: PropTypes.func,
    isParentTemplateAssistantSettings: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    settings: PropTypes.object,
};

TemplateAssistantTemplateHeader.defaultProps = {
    onHideNewTemplateHeader: null,
    isParentTemplateAssistantSettings: false,
    errors: [],
};

export default TemplateAssistantTemplateHeader;
