import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import Grid from '../../Grid';
import PropTypes from 'prop-types';
import Constants from '../../../helpers/Constants';
import Translations from '../../../helpers/Translations';
import Divider from '../../Divider';

const QuestionnaireEditorNewQuestionPicker = props => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <Dropdown
                    defaultOpen={props.defaultOpen}
                    text={Translations.getStatic('addNewQuestion')}
                    className="secondary-link txt-regular"
                    scrolling
                    inline
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => props.onAddQuestionItem(Constants.QUESTIONNAIRE_QUESTION_TYPE_ONE)}
                            text={Translations.getStatic('questionWithOneAnswer')}
                        />
                        <Dropdown.Item
                            onClick={() => props.onAddQuestionItem(Constants.QUESTIONNAIRE_QUESTION_TYPE_MORE)}
                            text={Translations.getStatic('questionWithMoreAnswers')}
                        />
                        <Dropdown.Item
                            onClick={() => props.onAddQuestionItem(Constants.QUESTIONNAIRE_QUESTION_TYPE_LONGTEXT)}
                            text={Translations.getStatic('textQuestion')}
                        />

                        {
                            props.usedQuestionTypes.find(type => type === Constants.QUESTIONNAIRE_QUESTION_TYPE_SALARY) === undefined
                            && (
                                <Dropdown.Item
                                    onClick={() => props.onAddQuestionItem(Constants.QUESTIONNAIRE_QUESTION_TYPE_SALARY)}
                                    text={Translations.getStatic('expectedSalary')}
                                />
                            )
                        }
                        {
                            props.usedQuestionTypes.find(type => type === Constants.QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE) === undefined
                            && (
                                <Dropdown.Item
                                    onClick={() => props.onAddQuestionItem(Constants.QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE)}
                                    text={Translations.getStatic('disposableDate')}
                                />
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </Grid.Column>
        </Grid.Row>
        {!props.modalType && <Divider hidden className="size_16 multiple" />}
    </Grid>
);

QuestionnaireEditorNewQuestionPicker.propTypes = {
    onAddQuestionItem: PropTypes.func.isRequired,
    usedQuestionTypes: PropTypes.array,
    defaultOpen: PropTypes.bool,
    modalType: PropTypes.string,
};

QuestionnaireEditorNewQuestionPicker.defaultProps = {
    defaultOpen: false,
    usedQuestionTypes: [],
};

const mapStateToProps = state => ({
    modalType: state.modalLogic.modalType,
});

export default connect(mapStateToProps)(QuestionnaireEditorNewQuestionPicker);
