export const handleZoomIn = (cropperRef) => {
    cropperRef.current?.zoom(0.1);
};
export const handleZoomOut = (cropperRef) => {
    cropperRef.current?.zoom(-0.1);
};
export const handleRotateLeft = (cropperRef) => {
    cropperRef.current?.rotate(-90);
};
export const handleRotateRight = (cropperRef) => {
    cropperRef.current?.rotate(90);
};
export const handleMoveLeft = (cropperRef) => {
    cropperRef.current?.move(10, 0);
};
export const handleMoveRight = (cropperRef) => {
    cropperRef.current?.move(-10, 0);
};
export const handleMoveUp = (cropperRef) => {
    cropperRef.current?.move(0, 10);
};
export const handleMoveDown = (cropperRef) => {
    cropperRef.current?.move(0, -10);
};
export const updateCroppedImage = (cropperRef, setCroppedImage, onCropChange, imgFormat) => {
    const cropper = cropperRef.current;
    if (cropper) {
        const croppedCanvas = cropper.getCroppedCanvas({
            fillColor: '#fff',
        });
        if (croppedCanvas) {
            const croppedDataUrl = croppedCanvas.toDataURL(`image/${imgFormat || 'jpeg'}`);
            setCroppedImage(croppedDataUrl);
            onCropChange(croppedDataUrl);
        }
    }
};
export const handleCropDebounced = (debounceTimeout, cropperRef, setCroppedImage, onCropChange, imgFormat) => {
    if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
        updateCroppedImage(cropperRef, setCroppedImage, onCropChange, imgFormat);
    }, 100);
};
