import { useMutation } from '@tanstack/react-query';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { UPDATE_COMPANY_DETAILS } from '../../keys';
export const useCompanyDetailsUpdate = (onSuccess, onError) => {
    const { api } = getConfiguration();
    return useMutation({
        mutationKey: [UPDATE_COMPANY_DETAILS],
        mutationFn: async (companyDetails) => {
            const response = await api.updateCompanyDetails(companyDetails);
            const companyDetailsUpdateResponse = {
                businessArea: {
                    id: companyDetails.businessAreaId,
                    names: response.businessArea.names,
                },
                characteristics: companyDetails.characteristics,
                languageFlag: companyDetails.languageFlag,
            };
            return companyDetailsUpdateResponse;
        },
        onSuccess,
        onError,
    });
};
