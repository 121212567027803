import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import DatePicker from '@profesia/adamui/components/common/datepicker/DatePicker/index';
import { useGetFixedT, useGetLocale } from '@profesia/mark-offer-form/i18n';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_JOB_AD, NAME_APPLICABLE_TILL, } from '@profesia/mark-offer-form/utils/track/constants';
import { FORM_APPLICABLE_TILL } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const ApplicableTill = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_APPLICABLE_TILL, control });
    const t = useGetFixedT('selectionProcessInfo.applicableTill');
    const currentLanguage = useGetLocale();
    const handleChange = (date) => {
        setValue(FORM_APPLICABLE_TILL, date);
    };
    return (_jsx(HiddenFields, { buttonLabel: t('label'), shouldBeHidden: !value, children: _jsx(DatePicker, { label: t('label'), value: value, onChange: handleChange, language: currentLanguage }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_APPLICABLE_TILL, null) }));
};
export default ApplicableTill;
