import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import Label from '@profesia/adamui/components/common/Label';
import { getColor } from '@profesia/adamui/styles/colors';
import { sanitizeTestId } from '@profesia/adamui/tests/testId';
import { tabletDown, desktopUp } from '@profesia/adamui/styles/theme';
import { SectionWrapperColorVariant } from '@profesia/adamui/components/common/types';
const SectionWrapper = ({ children, title, endComponent, isBoxed = true, className, testId, variant = SectionWrapperColorVariant.Light, }) => {
    const inputsWrapperTestid = `inputs-wrapper-${variant === SectionWrapperColorVariant.Dark ? 'dark' : 'light'}`;
    return (_jsxs(StyledBox, { className: `section-wrapper${className ? ` ${className}` : ''}`, hasHeader: !!title || !!endComponent, isBoxed: isBoxed, "data-testid": sanitizeTestId(testId, `section-wrapper${isBoxed ? '-boxed' : ''}`), children: [_jsxs(StyledHeaderBox, { children: [title && _jsx(StyledLabel, { children: title }), endComponent && _jsx(StyledEndComponent, { children: endComponent })] }), _jsx(StyledContent, { className: "inputs-wrapper", variant: variant, isBoxed: isBoxed, "data-testid": inputsWrapperTestid, children: children })] }));
};
const StyledLabel = styled(Label)(({ theme: { palette, spacing } }) => ({
    color: getColor(palette.primaryBlue),
    lineHeight: spacing(4),
    fontSize: spacing(2.5),
    fontWeight: 400,
    marginBottom: spacing(2),
    [tabletDown]: {
        marginLeft: spacing(2),
    },
}));
const StyledEndComponent = styled(Box)(({ theme: { spacing } }) => ({
    marginBottom: spacing(1.5),
    [tabletDown]: {
        marginRight: spacing(2),
    },
}));
const StyledHeaderBox = styled(Box)(() => ({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const StyledContent = styled(Box, {
    shouldForwardProp: (prop) => !['variant', 'isBoxed'].includes(prop),
})(({ theme: { spacing, palette }, variant, isBoxed }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
    borderRadius: spacing(0.5),
    [tabletDown]: {
        padding: spacing(2),
        ...(variant === SectionWrapperColorVariant.Dark ? { borderRadius: 0 } : {}),
    },
    [desktopUp]: {
        ...(isBoxed
            ? {
                boxShadow: '0px 2px 4px 0px #0000000F',
                marginBottom: spacing(2),
            }
            : {}),
    },
    ...(isBoxed
        ? {
            padding: spacing(4),
        }
        : {}),
    ...(variant === SectionWrapperColorVariant.Dark
        ? {
            padding: spacing(4),
            backgroundColor: getColor(palette.neutralLight1),
            border: `1px solid ${getColor(palette.neutralLight3)}`,
        }
        : { backgroundColor: getColor(palette.white) }),
}));
const styledBoxPropsToNotForward = ['hasHeader', 'isBoxed'];
const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => !styledBoxPropsToNotForward.includes(prop),
})(({ theme: { palette, spacing, breakpoints }, hasHeader, isBoxed }) => {
    const sharedStyles = {
        marginBottom: spacing(3),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: getColor(palette.neutralLight4),
        paddingBottom: spacing(4),
        ...(!hasHeader && { marginTop: spacing(1) }),
        '&:last-child': {
            borderBottom: 'none',
            marginBottom: 0,
        },
    };
    return {
        ...(!isBoxed ? sharedStyles : {}),
        [tabletDown]: sharedStyles,
    };
});
export default SectionWrapper;
