import { createTheme, keyframes, useMediaQuery } from '@mui/material';
import { getColor, palette } from '@profesia/adamui/styles/colors';
import { ArrowDownIcon } from '@profesia/adamui/components/common/icons';
import { fontFamily, fontWeights } from './fonts';
const modalTop = 69;
const slideInTwiceRight = keyframes `
    0% { right: 0; }
    37% { right: 0; }
    50% { right: 18%; }
    75% { right: 26%; }
    100% { right: 704px; }
`;
const slideOutFromTwiceRight = keyframes `
    0% { right: 704px; }
    50% { right: 0; }
    100% { right: 0; }
`;
const { spacing, breakpoints, components, typography } = createTheme();
const theme = createTheme({
    customComponents: {
        bookmarksBar: {
            width: 72,
        },
        tag: {
            borderWidth: 1,
        },
        tagField: {
            px: spacing(0.5),
            gap: spacing(0.5),
        },
        filterPanel: {
            paddingMobile: spacing(0, 2),
            paddingDesktop: spacing(0, 4),
        },
        modal: {
            top: modalTop,
        },
    },
    breakpoints: {
        values: {
            ...breakpoints.values,
            modalFullWidth: 992,
        },
    },
    other: {
        contentHeightLimit: {
            mobile: `calc(${window.innerHeight}px - 64px)`,
            desktop: 'calc(100vh - 64px)',
        },
    },
    typography: {
        fontFamily,
        h1: {
            fontSize: '1.75rem',
            lineHeight: 1.429,
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: fontWeights.fontWeightRegular,
            lineHeight: 1.5,
        },
        h3Medium: {
            fontFamily,
            fontSize: '1.25rem',
            lineHeight: 1.6,
            fontWeight: fontWeights.fontWeightMedium,
        },
        h3: {
            fontSize: '1.25rem',
            lineHeight: 1.6,
            fontWeight: fontWeights.fontWeightRegular,
        },
        h4: {
            fontSize: '1rem',
            lineHeight: 1.25,
            fontWeight: fontWeights.fontWeightMedium,
        },
        h5: {
            fontSize: '0.875rem',
            fontWeight: fontWeights.fontWeightMedium,
            lineHeight: 1.429,
        },
        h6: {
            fontSize: '0.75rem',
            fontWeight: fontWeights.fontWeightMedium,
            lineHeight: 1.333,
            textTransform: 'uppercase',
        },
        small: {
            fontFamily,
            fontSize: '0.75rem',
            lineHeight: 1.667,
        },
        icons: {
            modalAction: {
                fontSize: '2.375rem',
            },
            content: {
                color: palette.neutralDark2.main,
                fontSize: typography.body1.fontSize,
                margin: spacing(0.5, 1, 0, 0),
            },
        },
    },
    palette: {
        ...palette,
    },
    //@TODO zadefinovať globalne shadows toto je jeden zo spôsobov
    /* shadows: [...createTheme().shadows.map((shadow, i) => (
        i === 1 ? '0 0 18px 0 rgba(9, 32, 46, 0.2)' : shadow
    ))] as Shadows, */
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
                InputLabelProps: {
                    shrink: true,
                    variant: 'standard',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: spacing(0, 1, 0, 0),
                    borderRadius: 4,
                    '&:not(.Mui-disabled)': {
                        '.MuiFormControlLabel-root:hover &': {
                            boxShadow: '0px 0px 2px 3px rgba(39, 116, 240, 0.15)',
                        },
                    },
                    height: 18,
                    width: 18,
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: 0,
                    marginRight: spacing(1),
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: typography.body2.fontSize,
                    fontWeight: typography.fontWeightBold,
                    color: getColor(palette.neutralDark1),
                    marginBottom: 0,
                    maxWidth: '100%',
                    '&.Mui-focused': {
                        color: getColor(palette.neutralDark1),
                    },
                    '&.Mui-error': {
                        color: getColor(palette.secondaryRed),
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: {
            defaultProps: {
                labelPlacement: 'top',
            },
            styleOverrides: {
                root: {
                    margin: 0,
                    '& #placeholder, & ::placeholder': {
                        color: getColor(palette.black3),
                        opacity: '100%',
                    },
                    '& .MuiOutlinedInput-root': {
                        width: '100%',
                        backgroundColor: getColor(palette.white),
                        margin: spacing(1, 0),
                        minHeight: 32,
                        '& fieldset': {
                            borderColor: getColor(palette.black5),
                        },
                        '&:hover fieldset': {
                            borderColor: getColor(palette.primaryBlue),
                        },
                        '&.Mui-focused': {
                            borderColor: getColor(palette.primaryBlue),
                        },
                        '& fieldset legend': {
                            display: 'none',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            bottom: -1,
                            top: -1,
                        },
                    },
                },
                labelPlacementTop: {
                    width: '100%',
                    color: getColor(palette.neutralDark1),
                    justifyContent: 'space-between',
                    '> .MuiTypography-root': {
                        marginRight: 'auto',
                    },
                },
                labelPlacementStart: {
                    justifyContent: 'space-between',
                    gap: '1rem',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variant: 'body2',
                variantMapping: {
                    ...components?.MuiTypography?.defaultProps?.variantMapping,
                    h3Medium: 'h3',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: getColor(palette.primaryNavy),
                    padding: spacing(0.8, 1.75, 0.8, 1.5),
                    fontSize: typography.body2.fontSize,
                    '&.MuiSelect-overridePaddingLeft': {
                        paddingLeft: spacing(0.8),
                    },
                },
            },
            defaultProps: {
                IconComponent: ArrowDownIcon,
                MenuProps: {
                    PaperProps: {
                        className: 'MuiPaper-select',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.MuiPaper-select': {
                        borderRadius: 0,
                        maxHeight: 300,
                        '.MuiMenuItem-root.select-placeholder': {
                            display: 'none',
                        },
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '.MuiPaper-select &': {
                        padding: 0,
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    '&.menu-small li': {
                        paddingLeft: spacing(1),
                        paddingRight: spacing(1),
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '.MuiPaper-select &': {
                        fontSize: typography.body2.fontSize,
                        padding: spacing(1, 2),
                        '&:not(:last-of-type)': {
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderBottomColor: getColor(palette.neutralLight0),
                        },
                        '&:hover, &:focus': {
                            backgroundColor: getColor(palette.neutralLight1),
                        },
                        '&.Mui-selected': {
                            background: getColor(palette.neutralLight1),
                            '&.MuiMenuItem-checkbox': {
                                background: getColor(palette.white),
                            },
                            '&:hover, &.Mui-focusVisible': {
                                background: getColor(palette.neutralLight1),
                            },
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: getColor(palette.primaryNavy),
                    '.MuiSelect-icon': {
                        top: `calc(50% - 8px)`,
                        right: '11px',
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    '& > .MuiBox-root': {
                        position: 'fixed',
                        backgroundColor: 'white',
                        height: `calc(100% - ${modalTop}px)`,
                        bottom: 0,
                        outline: 0,
                        right: 0,
                        top: modalTop,
                    },
                    '.variant-noModal-content, .variant-modal-content': {
                        boxShadow: 'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px',
                    },
                    '&.variant-noModal': {
                        '.MuiBackdrop-root': {
                            background: 'transparent',
                            width: '100%',
                            height: '100%',
                            top: 'auto',
                            right: 'auto',
                        },
                        '.variant-noModal-content': {
                            '@media only screen and (max-width: 1500px)': {
                                width: '830px',
                            },
                            width: '968px',
                            height: '100%',
                            overflow: 'overlay',
                        },
                    },
                    '&.variant-modal': {
                        zIndex: 1299,
                        '.variant-modal-content': {
                            [breakpoints.down('md')]: {
                                width: '100vw',
                            },
                            [breakpoints.up('md')]: {
                                width: '704px',
                            },
                            height: '100%',
                            overflow: 'overlay',
                        },
                    },
                    '.modal-second': {
                        right: '768px',
                        WebkitAnimation: `${slideInTwiceRight} 0.3s ease-out forwards`,
                        MozAnimation: `${slideInTwiceRight} 0.3s ease-out forwards`,
                        OAnimation: `${slideInTwiceRight} 0.3s ease-out forwards`,
                        animation: `${slideInTwiceRight} 0.3s ease-out forwards`,
                    },
                    '.modal-second-closing': {
                        WebkitAnimation: `${slideOutFromTwiceRight} 0.3s ease-out forwards`,
                        MozAnimation: `${slideOutFromTwiceRight} 0.3s ease-out forwards`,
                        OAnimation: `${slideOutFromTwiceRight} 0.3s ease-out forwards`,
                        animation: `${slideOutFromTwiceRight} 0.3s ease-out forwards`,
                    },
                },
            },
        },
        MuiPickersPopper: {
            styleOverrides: {
                paper: {
                    boxShadow: 'none',
                },
            },
        },
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    '&': {
                        width: '284px',
                        height: '316px',
                        maxHeight: '316px',
                        border: '1px solid #C2CDDE',
                        borderRadius: spacing(0.5),
                        backgroundColor: '#F9FAFC',
                        boxShadow: '0px 12px 16px 0px #0000001A',
                    },
                    '.MuiMonthCalendar-root, .MuiYearCalendar-root': {
                        width: '284px',
                    },
                    '.MuiMonthCalendar-root': {
                        marginTop: spacing(3),
                        padding: spacing(0, 1.75),
                    },
                    '.MuiYearCalendar-root': {
                        padding: spacing(2, 1.75, 4),
                    },
                    '.MuiPickersCalendarHeader-root': {
                        paddingLeft: spacing(2),
                        paddingRight: spacing(1),
                        borderBottom: '1px solid #C2CDDE',
                        paddingBottom: spacing(1),
                        marginBottom: 0,
                    },
                    '.MuiPickersCalendarHeader-label': {
                        fontSize: typography.body2.fontSize,
                        color: getColor(palette.primaryNavy),
                    },
                    '.MuiPickersDay-root': {
                        width: spacing(4),
                        height: spacing(4),
                    },
                    '.MuiPickersMonth-monthButton, .MuiPickersYear-yearButton': {
                        width: '100%',
                        marginLeft: spacing(0.25),
                        marginRight: spacing(0.25),
                    },
                    '.MuiPickersDay-root, .MuiPickersMonth-monthButton, .MuiPickersYear-yearButton': {
                        fontSize: typography.body2.fontSize,
                        color: getColor(palette.primaryNavy),
                        borderRadius: spacing(0.5),
                        '&:hover, &:focus': {
                            backgroundColor: getColor(palette.neutralLight1),
                        },
                        '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
                            backgroundColor: getColor(palette.neutralDark2),
                        },
                    },
                    '.MuiPickersDay-dayOutsideMonth': {
                        color: getColor(palette.black3),
                    },
                    '.MuiDayCalendar-root': {
                        marginTop: spacing(1),
                    },
                    '.MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewButton': {
                        borderRadius: spacing(0.5),
                    },
                    '.MuiDayCalendar-weekDayLabel': {
                        width: spacing(4),
                        height: spacing(4),
                        color: getColor(palette.neutralDark2),
                    },
                },
            },
        },
    },
});
export const onBreakpoint = (breakpoint, onDown, onUp) => ({
    [theme.breakpoints.up(breakpoint)]: onUp ?? {},
    [theme.breakpoints.down(breakpoint)]: onDown ?? {},
});
export const useBreakpoint = (breakpoint, key) => useMediaQuery(theme.breakpoints[key](breakpoint));
export const useGetValueBasedOnBreakpoint = (breakpoint) => {
    const isDown = useBreakpoint(breakpoint, 'down');
    return (onDown, onUp) => (isDown ? onDown : onUp);
};
export const desktopUp = theme.breakpoints.up('md');
export const tabletDown = theme.breakpoints.down('md');
export const mobileDown = theme.breakpoints.down('sm');
export default theme;
