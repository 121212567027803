import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import TextWithLineBreaks from '@profesia/adamui/components/common/text/TextWithLineBreaks';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
const CompanyDetailsContent = ({ characteristics, businessAreaName, }) => {
    const t = useGetFixedT('companyDetails');
    return (_jsxs(Grid, { container: true, spacing: 2, rowSpacing: 1, "data-testid": "company-details-content", children: [_jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsx(Typography, { color: "primaryNavy", children: t('mainFocus') }) }), _jsx(Grid, { item: true, xs: 12, sm: 8, children: _jsx(Typography, { color: "primaryNavy", fontWeight: 700, children: businessAreaName }) }), _jsx(Grid, { item: true, xs: 12, sm: 4, mt: { xs: 3, sm: 0 }, children: _jsx(Typography, { color: "primaryNavy", children: t('briefDescription') }) }), _jsx(Grid, { item: true, xs: 12, sm: 8, children: characteristics ? (_jsx(Typography, { color: "primaryNavy", fontWeight: 700, children: _jsx(TextWithLineBreaks, { text: characteristics }) })) : null })] }));
};
export default CompanyDetailsContent;
