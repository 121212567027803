import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Constants from '../../../helpers/Constants';
import Divider from '../../Divider';
import QuestionnaireEditorTextQuestion from './QuestionnaireEditorTextQuestion';
import QuestionnaireEditorOneAnswerQuestion from './QuestionnaireEditorOneAnswerQuestion';
import QuestionnaireEditorMoreAnswersQuestion from './QuestionnaireEditorMoreAnswersQuestion';
import QuestionnaireEditorSalaryQuestion from './QuestionnaireEditorSalaryQuestion';
import QuestionnaireEditorDisposableDateQuestion from './QuestionnaireEditorDisposableDateQuestion';

class QuestionnaireEditorQuestionsList extends React.Component
{
    onUpdateQuestionItem = (questionIndex, text) => {
        this.props.onUpdateQuestionItem(questionIndex, {
            ...this.props.questions[questionIndex],
            question: text,
        });
    };

    onUpdateAnswerItem = (questionIndex, answerIndex, params) => {
        const replaceRequiredOptionWithScore = score => {
            if (score === Constants.QUESTIONNAIRE_ANSWER_VALUE_REQUIRED) {
                return 10;
            }

            return score;
        };

        this.props.onUpdateAnswerItem(questionIndex, answerIndex, {
            ...this.props.questions[questionIndex].answers[answerIndex],
            answer: params.hasOwnProperty('answer') === true
                ? params.answer
                : this.props.questions[questionIndex].answers[answerIndex].answer,
            score: params.hasOwnProperty('score') === true
                ? replaceRequiredOptionWithScore(params.score)
                : this.props.questions[questionIndex].answers[answerIndex].score,
            isRequired: params.hasOwnProperty('score') === true && params.score === Constants.QUESTIONNAIRE_ANSWER_VALUE_REQUIRED,
        });
    };

    render = () => {
        return this.props.questions.map((question, i) => {
            {!this.props.modalType && <Divider hidden className="size_16 multiple" />}
            switch (question.type) {
                case Constants.QUESTIONNAIRE_QUESTION_TYPE_ONE:
                case Constants.QUESTIONNAIRE_QUESTION_TYPE_SELECTBOX:
                    return (
                        <QuestionnaireEditorOneAnswerQuestion
                            key={i}
                            index={i}
                            question={question}
                            first={i === 0}
                            last={i === this.props.questions.length - 1}
                            onDeleteQuestionItem={this.props.onDeleteQuestionItem}
                            onUpdateQuestionItem={this.onUpdateQuestionItem}
                            onAddAnswerItem={this.props.onAddAnswerItem}
                            onDeleteAnswerItem={this.props.onDeleteAnswerItem}
                            onUpdateAnswerItem={this.onUpdateAnswerItem}
                            onReorderQuestionItem={this.props.onReorderQuestionItem}
                            displaySize={this.props.displaySize}
                        />
                    );

                case Constants.QUESTIONNAIRE_QUESTION_TYPE_MORE:
                    return (
                        <QuestionnaireEditorMoreAnswersQuestion
                            key={i}
                            index={i}
                            question={question}
                            first={i === 0}
                            last={i === this.props.questions.length - 1}
                            onUpdateQuestionItem={this.onUpdateQuestionItem}
                            onDeleteQuestionItem={this.props.onDeleteQuestionItem}
                            onAddAnswerItem={this.props.onAddAnswerItem}
                            onDeleteAnswerItem={this.props.onDeleteAnswerItem}
                            onUpdateAnswerItem={this.onUpdateAnswerItem}
                            onReorderQuestionItem={this.props.onReorderQuestionItem}
                            displaySize={this.props.displaySize}
                        />
                    );

                case Constants.QUESTIONNAIRE_QUESTION_TYPE_TEXT:
                case Constants.QUESTIONNAIRE_QUESTION_TYPE_LONGTEXT:
                    return (
                        <QuestionnaireEditorTextQuestion
                            key={i}
                            index={i}
                            question={question}
                            first={i === 0}
                            last={i === this.props.questions.length - 1}
                            onDeleteQuestionItem={this.props.onDeleteQuestionItem}
                            onUpdateQuestionItem={this.onUpdateQuestionItem}
                            onReorderQuestionItem={this.props.onReorderQuestionItem}
                        />
                    );
                case Constants.QUESTIONNAIRE_QUESTION_TYPE_SALARY:
                    return (
                        <QuestionnaireEditorSalaryQuestion
                            key={i}
                            index={i}
                            question={question}
                            first={i === 0}
                            last={i === this.props.questions.length - 1}
                            onDeleteQuestionItem={this.props.onDeleteQuestionItem}
                            onUpdateQuestionItem={this.onUpdateQuestionItem}
                            onReorderQuestionItem={this.props.onReorderQuestionItem}
                        />
                    );
                case Constants.QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE:
                    return (
                        <QuestionnaireEditorDisposableDateQuestion
                            key={i}
                            index={i}
                            question={question}
                            first={i === 0}
                            last={i === this.props.questions.length - 1}
                            onDeleteQuestionItem={this.props.onDeleteQuestionItem}
                            onUpdateQuestionItem={this.onUpdateQuestionItem}
                            onReorderQuestionItem={this.props.onReorderQuestionItem}
                        />
                    );
            }
        });
    };
}

const mapStateToProps = state => ({
    displaySize: state.displaySize,
    questionnaireEditorModel: state.questionnaireEditorModel,
    modalType: state.modalLogic.modalType,
});

QuestionnaireEditorQuestionsList.propTypes = {
    onDeleteQuestionItem: PropTypes.func.isRequired,
    onUpdateQuestionItem: PropTypes.func.isRequired,
    onAddAnswerItem: PropTypes.func.isRequired,
    onDeleteAnswerItem: PropTypes.func.isRequired,
    onUpdateAnswerItem: PropTypes.func.isRequired,
    onReorderQuestionItem: PropTypes.func.isRequired,
    modalType: PropTypes.string,
};

QuestionnaireEditorQuestionsList.defaultProps = {
    questions: [],
};

export default connect(mapStateToProps)(QuestionnaireEditorQuestionsList);
