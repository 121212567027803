import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ListSubheader, FormControl, Box, styled, FormHelperText, Paper, } from '@mui/material';
import Label from '@profesia/adamui/components/common/Label';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
import { ValidationMessage } from '../../input';
import BaseAutocomplete from './BaseAutocomplete';
import AutocompleteInput from './AutocompleteInput';
import NoOptionsText from './NoOptionsText';
import AutocompleteOption from './Option';
const Autocomplete = ({ labelPlacementLeft, label, value, onChange, placeholder, required = false, isDisabled = false, noOptionsText, hasError, errorMessage, optionalLabel, helperText, options, disableClearable, }) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleChange = (event, selectedOption) => {
        setOpen(false);
        onChange(event, selectedOption, 'selectOption');
        inputRef.current?.blur();
    };
    return (_jsx(_Fragment, { children: _jsxs(StyledFormControl, { labelPlacementLeft: labelPlacementLeft, error: hasError, children: [label ? (_jsx(Box, { ...(labelPlacementLeft ? { mb: 0, mr: 3.625 } : { mb: 0.5, mr: 0 }), children: _jsx(Label, { required: required, optionalLabel: optionalLabel, "data-testid": "autocomplete-label", children: label }) })) : null, _jsx(BaseAutocomplete, { open: open, onOpen: handleOpen, onClose: handleClose, "data-testid": "autocomplete", onChange: onChange, options: options, disabled: isDisabled, disableClearable: required || disableClearable, clearOnEscape: true, noOptionsText: _jsx(NoOptionsText, { children: noOptionsText }), value: value, groupBy: (option) => option.categoryName?.toUpperCase() || '', getOptionLabel: (option) => option.name || '', PaperComponent: ({ children }) => (_jsx(StyledPaper, { "data-testid": "list-box", children: children })), isOptionEqualToValue: (option, value) => option.id === value?.id, renderInput: (params) => (_jsx(AutocompleteInput, { ...params, hasTags: false, placeholder: placeholder, isError: hasError, onChange: (event) => setInputValue(event.target.value || ''), ref: inputRef })), renderOption: (props, option) => (_jsx(AutocompleteOption, { liProps: props, option: option, onOptionSelect: handleChange, inputValue: inputValue }, option.id)), renderGroup: (params) => {
                        return (_jsxs("div", { children: [params.group ? (_jsx(StyledListSubheader, { "data-testid": "list-subheader", children: _jsx(Typography, { color: "black3", fontWeight: 700, children: params.group }) })) : null, _jsx(GroupItems, { children: _jsx(Typography, { color: "primaryNavy", children: params.children }) })] }, params.key));
                    } }), helperText ? _jsx(FormHelperText, { children: helperText }) : null, errorMessage ? (_jsx(ValidationMessage, { mt: 0.5, children: errorMessage })) : null] }) }));
};
const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
}));
const StyledListSubheader = styled(ListSubheader)(({ theme: { spacing } }) => ({
    margin: 0,
    padding: spacing(0.5, 2, 0, 2),
    position: 'relative',
}));
const GroupItems = styled('ul')(({ theme: { spacing, palette } }) => ({
    padding: 0,
    margin: 0,
    listStyle: 'none',
    '& li': {
        padding: spacing(1, 2),
        borderBottom: '0.5px solid',
        borderBottomColor: getColor(palette.neutralLight0),
        height: 32,
    },
}));
const StyledFormControl = styled(FormControl, {
    shouldForwardProp: (prop) => prop !== 'labelPlacementLeft',
})(({ labelPlacementLeft }) => {
    return {
        display: 'flex',
        ...(labelPlacementLeft
            ? { alignItems: 'center', flexDirection: 'row' }
            : { alignItems: 'space-between', flexDirection: 'column' }),
        '.MuiInputLabel-root': {
            position: 'relative',
            transform: 'none',
        },
    };
});
export default Autocomplete;
