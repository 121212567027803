import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import ImageCropperToolbar from './toolbar';
import { handleCropDebounced, handleMoveDown, handleMoveLeft, handleMoveRight, handleMoveUp, handleRotateLeft, handleRotateRight, handleZoomIn, handleZoomOut, } from './cropperUtils';
const ImageCropper = ({ imageSrc, mainLabel, previewLabel, aspect, onCropChange, imgFormat, maxCropperBoxHeight, maxPreviewBoxHeight, }) => {
    const imageRef = useRef(null);
    const cropperRef = useRef(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const debounceTimeout = useRef(null);
    useEffect(() => {
        return () => {
            cropperRef.current = null;
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);
    const initCropper = () => {
        if (imageRef.current) {
            cropperRef.current = new Cropper(imageRef.current, {
                aspectRatio: aspect,
                viewMode: 0,
                autoCropArea: 1,
                guides: true,
                rotatable: true,
                zoomable: true,
                crop: () => handleCropDebounced(debounceTimeout, cropperRef, setCroppedImage, onCropChange, imgFormat),
            });
        }
    };
    return imageSrc ? (_jsxs(Stack, { display: "flex", flexDirection: "column", gap: 0.5, children: [_jsx(Box, { children: _jsx(Typography, { color: "neutralDark1", variant: "h5", children: mainLabel }) }), _jsx(CropperWrapper, { maxHeight: maxCropperBoxHeight, height: maxCropperBoxHeight, children: _jsx("img", { ref: imageRef, src: imageSrc, onLoad: initCropper, "data-testid": "cropper-img" }) }), _jsx(ImageCropperToolbar, { handleZoomIn: () => handleZoomIn(cropperRef), handleZoomOut: () => handleZoomOut(cropperRef), handleRotateLeft: () => handleRotateLeft(cropperRef), handleRotateRight: () => handleRotateRight(cropperRef), handleMoveLeft: () => handleMoveLeft(cropperRef), handleMoveRight: () => handleMoveRight(cropperRef), handleMoveUp: () => handleMoveUp(cropperRef), handleMoveDown: () => handleMoveDown(cropperRef) }), _jsx(Box, { children: _jsx(Typography, { color: "neutralDark1", variant: "h5", children: previewLabel }) }), _jsx(PreviewWrapper, { maxHeight: maxPreviewBoxHeight, children: croppedImage ? (_jsx("img", { src: croppedImage, height: maxPreviewBoxHeight, "data-testid": "preview-img" })) : null })] })) : null;
};
const CropperWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
const PreviewWrapper = styled(Box)(({ theme: { palette } }) => ({
    border: `1px solid ${getColor(palette.neutralLight5)}`,
    borderRadius: '4px',
    width: 'fit-content',
    boxSizing: 'content-box',
}));
export default ImageCropper;
