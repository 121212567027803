import {
    FormT,
    SalaryFixed,
    SalaryRange,
    Salary,
    SalaryCommon,
} from '@profesia/mark-offer-form/components/content/form/types';
import {
    SuitableFor,
    LanguageRequirements,
    SkillLevel,
    LanguageLevel,
    EducationLevel,
} from '@profesia/mark-offer-form/codebooks';
import { Position, Skill, Region, LanguageSkill, ReceivedData } from './types';

export const mapReceivedDataToForm = (data: ReceivedData): Omit<FormT, 'company'> => {
    const salary = data.salary;

    let salaryObject: Salary | null = null;

    if (salary) {
        salaryObject =
            salary.maximumValue === null
                ? ({
                      type: 'fixed',
                      value: Number(salary.minimumValue),
                      currency: `${salary.currencyId}`,
                      period: `${salary.salaryPeriodId}`,
                      otherInformation: salary.additionalInformation ?? null,
                  } as SalaryFixed & SalaryCommon)
                : ({
                      type: 'range',
                      from: Number(salary.minimumValue),
                      to: Number(salary.maximumValue),
                      showTo: salary.publishMaximumValue,
                      currency: `${salary.currencyId}`,
                      period: `${salary.salaryPeriodId}`,
                      otherInformation: salary.additionalInformation ?? null,
                  } as SalaryRange & SalaryCommon);
    }

    return {
        jobTitle: data.title,
        language: data.language,
        jobPositions: data.positions.map((position: Position) => ({
            id: position.positionId.toString(),
            categoryId: position.categoryId.toString(),
        })),
        employmentTypes: data.jobTypes,
        jobDescription: data.description,
        educationLevel: data.educationLevel.toString() as EducationLevel,
        positionPlace: {
            type: data.workplaceTypeId,
            address: data.googlePlaceId
                ? [
                      {
                          value: data.place,
                          id: data.googlePlaceId,
                      },
                  ]
                : [],
            startDate: data.startDate,
            regions: data.regions?.map((region: Region) => ({
                id: region.regionId.toString(),
            })),
        },
        skills: {
            others: data.skills.map((item: Skill) => ({
                id: item.skillId?.toString(),
                level: item.levelId?.toString() as SkillLevel,
                value: item.skillName,
            })),
            personalSkills: data.personalityRequirements,
            drivingLicence: data.drivingLicences,
            experienceAtPosition: data.experienceInField,
            yearsOfExperience: data.experienceYears,
        },
        benefits: data.benefits,
        educationSpecialization: data.educationSpecialization,
        offerSuitableFor: [
            ...(data.refugeeFriendly ? [SuitableFor.Refugees] : []),
            ...(data.suitableForHighSchoolStudent ? [SuitableFor.HighSchoolStudents] : []),
            ...(data.suitableForUniversityStudent ? [SuitableFor.UniversityStudents] : []),
            ...(data.validForGraduate ? [SuitableFor.Graduates] : []),
        ],
        languages: {
            required: data.allLanguagesRequired
                ? LanguageRequirements.All
                : LanguageRequirements.All,
            items: data.languageSkills.map((item: LanguageSkill) => ({
                id: item.languageId.toString(),
                level: item.levelId.toString() as LanguageLevel,
            })),
        },
        salary: salaryObject,
        noteForCandidate: data.noteForCandidate ?? '', // @TODO: remove defaults after BE will add data
        numberOfOpenings: data.numberOfOpenings?.toString() ?? '', // @TODO: remove defaults after BE will add data
        applicableTill: data.applicableTill ? new Date(data.applicableTill) : null,
        isApplicableWithoutCv: data.applyWithoutResume ?? false, // @TODO: remove defaults after BE will add data
        shouldBeNotifiedAboutNewResumes: data.newCandidatesNotification ?? false, // @TODO: remove defaults after BE will add data
        referenceNumber: data.referenceNumber ?? '', // @TODO: remove defaults after BE will add data
    };
};
