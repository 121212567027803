import { Dispatch, Action } from 'redux';
import { CompanyDetailsUpdateResponse } from '@profesia/mark-offer-form/services/types';
import apiRequest from './apiRequest';
import { CompanyData, CompanyDataPayload } from './types';
import { SET_COMPANY_DATA } from '../../../actions/LoginActions';
import Language from '../../../helpers/Language';

const updateCompanyDetails = async (companyData: CompanyData, dispatch: Dispatch<Action>): Promise<CompanyDetailsUpdateResponse> => {
    const { companyId, businessAreaId, characteristics, languageFlag } = companyData;
    const pageLanguage = Language.get();

    if (!companyId) {
        throw new Error('Company ID is required.');
    }

    const payload: CompanyDataPayload = {
        businessAreaId: businessAreaId,
        shortCharacteristic: characteristics,
        languageFlag: languageFlag,
    };

    try {
        const response = await apiRequest<CompanyDetailsUpdateResponse>(`company/${companyId}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        });

        dispatch({
            type: SET_COMPANY_DATA,
            payload: {
                businessArea: response.businessArea,
                companyCharacteristics: { [languageFlag]: companyData.characteristics },
                ...(pageLanguage === languageFlag && { characteristics: companyData.characteristics }),
            },
        });

        return response;
    } catch (error) {
        throw new Error('Failed to update company details');
    }
};

export default updateCompanyDetails;
