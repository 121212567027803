import { jsx as _jsx } from "react/jsx-runtime";
import OptionItem from '@profesia/adamui/components/common/OptionItem';
import { FREE_VALUE } from '../../textField/ComboTagger/consts';
import OptionLabel from '../OptionLabel';
const Option = ({ option, liProps, isHidden, onOptionSelect, inputValue }) => {
    const { id, name = '' } = option;
    const onClick = (event) => {
        onOptionSelect(event, option);
    };
    const { onClick: _, key, ...otherLiProps } = liProps ?? {};
    return (_jsx(OptionItem, { divider: true, id: !id ? FREE_VALUE : undefined, value: id, onClick: onClick, isHidden: isHidden, ...otherLiProps, children: _jsx(OptionLabel, { label: name, isHighlightingEnabled: Boolean(id), inputValue: inputValue }) }));
};
export default Option;
