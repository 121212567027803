import { useEffect, useState } from 'react';
const useDateFnsLocale = (localeName) => {
    const [locale, setLocale] = useState(null);
    const localeMap = {
        sk: import('date-fns/locale/sk'),
        cs: import('date-fns/locale/cs'),
        en: import('date-fns/locale/en-GB'),
        de: import('date-fns/locale/de'),
        hu: import('date-fns/locale/hu'),
    };
    useEffect(() => {
        async function loadLocale() {
            try {
                const importedLocale = await localeMap[localeName];
                setLocale(importedLocale.default);
            }
            catch (error) { }
        }
        loadLocale();
    }, [localeName]);
    return locale;
};
export default useDateFnsLocale;
