import React from 'react';
import { connect } from 'react-redux';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import PropTypes from 'prop-types';
import Constants from '../../helpers/Constants';
import fileImage from '../../../img/icons/file.svg';
import User from '../../helpers/User';
import AttachmentOptions from './AttachmentOptions';

const CandidateAttachmentColumn = ({
    attachment,
    language,
    attachmentTypeName,
    onRemoveCandidateAttachment,
    isDuplicate,
    displaySize,
    loginManagerModel,
    jobModel,
}) => {
    const openAttachment = () => {
        window.open(attachment.url);
    };

    const image =
        attachment.attachmentTypeId === Constants.ATTACHMENT_TYPE_PHOTO &&
        attachment.thumbUrl !== null ? (
            <span onClick={openAttachment} className={'photo no-contacted pointer'}>
                <Image src={attachment.thumbUrl} />
            </span>
        ) : (
            <Image onClick={openAttachment} className="pointer" size="large" src={fileImage} />
        );

    const isAdmin = User.isAdmin(loginManagerModel);

    return (
        <Item className="data-block__content attachment">
            {image}
            <Grid verticalAlign="middle" container>
                <Grid.Row>
                    <Grid.Column width={displaySize.isMobile === true ? 12 : 9}>
                        <Item.Header as="h4" className="ellipsis pointer" onClick={openAttachment}>
                            {attachmentTypeName}
                        </Item.Header>
                        <Item.Meta className="ellipsis">{attachment.filename}</Item.Meta>
                    </Grid.Column>
                    <Grid.Column width={displaySize.isMobile === true ? 4 : 7} textAlign="right">
                        <AttachmentOptions
                            language={language}
                            isDuplicate={isDuplicate}
                            displaySize={displaySize}
                            attachment={attachment}
                            openAttachment={openAttachment}
                            onRemoveCandidateAttachment={onRemoveCandidateAttachment}
                            isAdmin={isAdmin}
                            loginManagerModel={loginManagerModel}
                            jobModel={jobModel}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>
    );
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
    jobModel: state.jobModel,
});

CandidateAttachmentColumn.propTypes = {
    attachment: PropTypes.object.isRequired,
    attachmentTypeName: PropTypes.string.isRequired,
    onRemoveCandidateAttachment: PropTypes.func.isRequired,
    isDuplicate: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(CandidateAttachmentColumn);
