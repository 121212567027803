import { EMAIL_REGEXP, PHONE_REGEXP } from '@profesia/mark-offer-form/codebook/validationRules';
import addError from '../addError';
const getEmailErrors = (email, t) => {
    const isEmailInvalid = Boolean(email) && !new RegExp(EMAIL_REGEXP).test(email);
    return {
        ...addError('email', !email, t('contactPerson.email.required')),
        ...addError('email', isEmailInvalid, t('contactPerson.email.invalid')),
    };
};
const getPhoneErrors = (phone, t) => {
    const sanitizedPhone = phone.replace(/\s+/g, '');
    const isPhoneNumberInvalid = Boolean(sanitizedPhone)
        ? !new RegExp(PHONE_REGEXP).test(sanitizedPhone)
        : false;
    return {
        ...addError('phone', isPhoneNumberInvalid, t('contactPerson.phone.invalid')),
    };
};
const getContactPersonErrors = ({ contactPerson }, t) => {
    let errors = {};
    const contactPersonErrors = {
        ...getEmailErrors(contactPerson.email, t),
        ...getPhoneErrors(contactPerson.phone, t),
    };
    if (Object.keys(contactPersonErrors).length > 0) {
        errors = { contactPerson: contactPersonErrors };
    }
    return errors;
};
export default getContactPersonErrors;
