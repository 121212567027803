import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getInputIcon } from '@profesia/adamui/components/common/icons/helpers';
import { HIDE_ON_HOVER, SHOW_ON_HOVER } from '@profesia/adamui/components/common/icons/classes';
const mappedIconTypes = {
    checked: {
        primary: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { x: "0.5", y: "0.5", width: "17", height: "17", rx: "8.5", fill: "white", stroke: "#506B95" }), _jsx("rect", { x: "5", y: "5", width: "8", height: "8", rx: "4", fill: "#2774F0" })] })),
        },
        disabled: {
            path: (_jsxs("g", { opacity: "0.5", children: [_jsx("rect", { x: "0.5", y: "0.5", width: "17", height: "17", rx: "8.5", fill: "white", stroke: "#555555" }), _jsx("rect", { x: "5", y: "5", width: "8", height: "8", rx: "4", fill: "#7F7F7F" })] })),
        },
    },
    unchecked: {
        primary: {
            path: (_jsx("rect", { x: "0.5", y: "0.5", width: "17", height: "17", rx: "8.5", fill: "white", stroke: "#506B95" })),
        },
        disabled: {
            path: (_jsx("rect", { opacity: "0.5", x: "0.5", y: "0.5", width: "17", height: "17", rx: "8.5", fill: "white", stroke: "#555555" })),
        },
    },
};
const circleIcons = {
    checked: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "11", cy: "11", r: "10", fill: "#2774F0" }), _jsx("circle", { className: SHOW_ON_HOVER, cx: "11", cy: "11", r: "10", stroke: "#2774F0", strokeOpacity: "0.25", strokeWidth: "2" }), _jsx("path", { d: "M14.2929 8.29301L9.99992 12.586L7.70692 10.293C7.31454 9.91404 6.69083 9.91946 6.3051 10.3052C5.91937 10.6909 5.91395 11.3146 6.29292 11.707L9.29292 14.707C9.68342 15.0974 10.3164 15.0974 10.7069 14.707L15.7069 9.70701C16.0859 9.31463 16.0805 8.69093 15.6947 8.30519C15.309 7.91946 14.6853 7.91404 14.2929 8.29301Z", fill: "white" })] })),
    unchecked: (_jsxs(_Fragment, { children: [_jsx("circle", { className: HIDE_ON_HOVER, opacity: "0.5", cx: "11", cy: "11", r: "9", fill: "white", stroke: "#B4B4B4" }), _jsxs("g", { className: SHOW_ON_HOVER, fill: "none", opacity: "0.25", children: [_jsx("circle", { cx: "11", cy: "11", r: "10", stroke: "#2774F0", strokeWidth: "2" }), _jsx("circle", { cx: "11", cy: "11", r: "10", stroke: "#2774F0", strokeOpacity: "0.25", strokeWidth: "2" })] })] })),
};
const RadioButton = ({ state = 'unchecked', variant = 'circle', type = 'primary', ...props }) => {
    const [iconPath, variantRelatedProps] = variant === 'default'
        ? [mappedIconTypes[state][type].path, {}]
        : [circleIcons[state], { viewBox: '0 0 22 22', height: 20, width: 20 }];
    return getInputIcon(iconPath, 'RadioButton', state, { ...props, ...variantRelatedProps });
};
export default RadioButton;
