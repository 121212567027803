import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
const LogoContext = createContext({
    base64Logo: null,
    isEdited: true,
    setIsEdited: () => {
        throw new Error('setIsEdited must be used within a LogoProvider');
    },
    setBase64Logo: () => {
        throw new Error('setBase64Logo must be used within a LogoProvider');
    },
});
const LogoProvider = ({ children, base64Logo: initialBase64Logo, isEdited: initialIsEdited, }) => {
    const [base64Logo, setBase64Logo] = useState(initialBase64Logo);
    const [isEdited, setIsEdited] = useState(initialIsEdited);
    return (_jsx(LogoContext.Provider, { value: { base64Logo, setBase64Logo, isEdited, setIsEdited }, children: children }));
};
export const useLogoContext = () => useContext(LogoContext);
export default LogoProvider;
